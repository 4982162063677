/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import styled from 'styled-components'
import { PageProps, graphql } from 'gatsby'
import { MDXProvider, MDXProviderComponentsProp } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import MainLayout from 'components/layouts/Main'
import Container from 'components/common/Container'
import ContentPanel from 'components/common/ContentPanel'

import appContent from 'content/app.json'
import colors from 'lib/utils/colors'
import InstructionsProvider from 'components/providers/InstructionsProvider'
import withAuth from 'lib/utils/withAuth'

const ContentContainer = styled(Container)`
  text-align: left;
  flex-direction: column;
  justify-content: initial;
  align-items: initial;
`

const RenderContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  color: black;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
`

const CustomH1 = styled.h1`
  font-size: 21px;
  line-height: 26px;
  letter-spacing: 0.02em;
`
const CustomBlockQuote = styled.blockquote`
  padding: 12px 16px;
  margin: 0px;
  background-color: ${colors.ShadowLight};
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.03em;
  margin-bottom: 24px;
`

type FaqsQueryProps = {
  data: {
    mdx: {
      body: string
      frontmatter: {
        headerTitle: string
      }
    }
  }
}

type FAQsPageProps = PageProps & FaqsQueryProps

const components: MDXProviderComponentsProp = {
  h1: CustomH1,
  q: CustomBlockQuote,
}

const FAQsPage: React.FC<FAQsPageProps> = ({ location, data }) => {
  const [openedExplanations, setOpenedExplanations] = React.useState<string[]>([])
  const [activeItem, setActiveItem] = React.useState<number | null>(null)

  const handleActiveItem = React.useCallback((item: number) => {
    setActiveItem((current) => (item === current ? null : item))
  }, [])

  const handleToggle = (reference: string) => {
    const foundIndex = openedExplanations.indexOf(reference)

    setOpenedExplanations((current) =>
      foundIndex > -1 ? current.filter((item) => item !== reference) : [...current, reference]
    )
  }

  return (
    <InstructionsProvider value={{ onToggle: handleToggle, openedExplanations }}>
      <MainLayout
        backgroundColor={appContent.backgroundColor}
        showTabBar
        currentPath={location.pathname}
        pageTitle={data.mdx.frontmatter.headerTitle}
        showBackButton
        fixedHeader
        content={
          <ContentContainer>
            <ContentPanel>
              <MDXProvider components={components}>
                <RenderContainer>
                  <MDXRenderer activeItem={activeItem} onClick={handleActiveItem}>
                    {data.mdx.body}
                  </MDXRenderer>
                </RenderContainer>
              </MDXProvider>
            </ContentPanel>
          </ContentContainer>
        }
      />
    </InstructionsProvider>
  )
}

export const query = graphql`
  query FaqsQuery {
    mdx(frontmatter: { name: { eq: "faqs" } }) {
      body
      frontmatter {
        headerTitle
      }
    }
  }
`

export default withAuth(FAQsPage)
